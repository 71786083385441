import { createSlice } from "@reduxjs/toolkit"

export const orderSlice = createSlice({
    name: "order",
    initialState: {
        orderSummary: {},
        orderList: [],
        invoiceList: [],
        OnSelectProductsList: [],
        productsList: [],
        categoryList: [],
        pendingOrderList: [],
        confirmedOrderList: [],
        deliveredOrderList: [],
        cancelledOrderList: [],
        ongoingOrderList: [],
        orderCount: {},
        exportList: [],
        orderDetail: {},
        isFreshData: false,
        totalEntry: 0,
        totalEntryPending: 0,
        totalEntryOngoing: 0,
        totalEntryConfirm: 0,
        totalEntryDeliver: 0,
        totalEntryCancel: 0,
        dealerList: [],
        agentList: [],
        paymentList: [],
        orderInvoiceResponse: {}
    },

    reducers: {
        setResponse: (state, action) => {
            state.orderInvoiceResponse = action.payload
        },
        setInvoiceList: (state, action) => {
            state.invoiceList = action.payload
        },
        setOrderSummary: (state, action) => {
            state.orderSummary = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setDealerList: (state, action) => {
            state.dealerList = action.payload
        },
        setAgentList: (state, action) => {
            state.agentList = action.payload
        },
        setProductList: (state, action) => {
            state.productsList = action.payload
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setTotalCount: (state, action) => {
            state.orderCount = action.payload
        },
        setOrderList: (state, action) => {
            state.orderList = action.payload
        },
        setPendingOrderList: (state, action) => {
            state.pendingOrderList = action.payload
        },
        setConfirmedOrderList: (state, action) => {
            state.confirmedOrderList = action.payload
        },
        setDeliveredOrderList: (state, action) => {
            state.deliveredOrderList = action.payload
        },
        setCancelledOrderList: (state, action) => {
            state.cancelledOrderList = action.payload
        },
        setOngoingOrderList: (state, action) => {
            state.ongoingOrderList = action.payload
        },

        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setTotalEntryPending: (state, action) => {
            state.totalEntryPending = action.payload
        },
        setTotalEntryOnGoing: (state, action) => {
            state.totalEntryOngoing = action.payload
        },
        setTotalEntryConfirm: (state, action) => {
            state.totalEntryConfirm = action.payload
        },
        setTotalEntryDeliver: (state, action) => {
            state.totalEntryDeliver = action.payload
        },
        setTotalEntryCancel: (state, action) => {
            state.totalEntryCancel = action.payload
        },
        setOnSelectProductList: (state, action) => {
            state.OnSelectProductsList = action.payload
        },
        setSingleData: (state, action) => {
            state.orderDetail = action.payload
        },
        setPaymentList: (state, action) => {
            state.paymentList = action.payload
        },
    }
})

export const {
    setResponse,
    setFreshList,
    setOrderSummary,
    setOnSelectProductList,
    setOngoingOrderList,
    setDealerList,
    setAgentList,
    setOrderList,
    setInvoiceList,
    setProductList,
    setCategoryList,
    setTotalCount,
    setPendingOrderList,
    setConfirmedOrderList,
    setDeliveredOrderList,
    setExportList,
    setCancelledOrderList,
    setSingleData,
    setTotalEntry,
    setTotalEntryPending,
    setTotalEntryOnGoing,
    setTotalEntryConfirm,
    setTotalEntryDeliver,
    setTotalEntryCancel,
    setFilteredList,
    setPaymentList
} = orderSlice.actions

export const selectOnProductList = (state) => state.order.OnSelectProductsList
export const selectOrderResponse = (state) => state.order.orderInvoiceResponse
export const selectOrderList = (state) => state.order.orderList
export const selectOrderSummary = (state) => state.order.orderSummary
export const selectInvoiceList = (state) => state.order.invoiceList
export const selectProductList = (state) => state.order.productsList
export const selectCategoryList = (state) => state.order.categoryList
export const selectDealerList = (state) => state.order.dealerList
export const selectAgentList = (state) => state.order.agentList
export const selectOrderCount = (state) => state.order.orderCount
export const selectFreshData = (state) => state.order.isFreshData
export const selectPendingOrderList = (state) => state.order.pendingOrderList
export const selectConfirmedOrderList = (state) => state.order.confirmedOrderList
export const selectDeliveredOrderList = (state) => state.order.deliveredOrderList
export const selectCancelOrderList = (state) => state.order.cancelledOrderList
export const selectOngoingOrderList = (state) => state.order.ongoingOrderList
export const selectExportList = (state) => state.order.exportList
export const selectTotalEntry = (state) => state.order.totalEntry
export const selectTotalEntryPending = (state) => state.order.totalEntryPending
export const selectTotalEntryOngoing = (state) => state.order.totalEntryOngoing
export const selectTotalEntryConfirm = (state) => state.order.totalEntryConfirm
export const selectTotalEntryDeliver = (state) => state.order.totalEntryDeliver
export const selectTotalEntryCancel = (state) => state.order.totalEntryCancel
export const selectOrderDetail = (state) => state.order.orderDetail
export const selectedPaymentList = (state) => state.order.paymentList

export default orderSlice.reducer
