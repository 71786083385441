import { createSlice } from "@reduxjs/toolkit"

export const commissionSlice = createSlice({
    name: "commission",
    initialState: {
        commissionList: [],
        filterList: [],
        agentList: [],
        commissionDetail: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setCommissionList: (state, action) => {
            state.commissionList = action.payload
        },
        setFilterList: (state, action) => {
            state.filterList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setAgentList: (state, action) => {
            state.agentList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.categoryDetail = action.payload
        }
    }
})

export const {
    setCommissionList,
    setFilterList,
    setFreshList,
    setAgentList,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = commissionSlice.actions

export const selectCommissionList = (state) => state.commission.commissionList
export const selectFilterList = (state) => state.commission.filterList
export const selectFreshData = (state) => state.commission.isFreshData
export const selectAgentList = (state) => state.commission.agentList
export const selectTotalEntry = (state) => state.commission.totalEntry
export const selectSingleDetail = (state) => state.commission.commissionDetail

export default commissionSlice.reducer
