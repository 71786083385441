import { createSlice } from "@reduxjs/toolkit"

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        totalOverviewList: [],
        totalCommissionList: [],
        totalOrder: {},
        totalDealer: {},
        totalAgent: {},
        isFreshData: false,
        totalEntry: 0,
    }, 
    reducers: {
        setTotalOverviewList: (state, action) => {
            state.totalOverviewList = action.payload
        },
        setTotalCommissionList: (state, action) => {
            state.totalCommissionList = action.payload
        },
        setTotalOverviewList: (state, action) => {
            state.totalOverviewList = action.payload
        },
        setTotalOverviewList: (state, action) => {
            state.totalOverviewList = action.payload
        },
        setTotalOrderList: (state, action) => {
            state.totalOrder = action.payload
        },
        setTotalDealerList: (state, action) => {
            state.totalDealer = action.payload
        },
        setTotalAgentList: (state, action) => {
            state.totalAgent = action.payload
        },
    }
})

export const {

    setTotalOverviewList,
    setTotalAgentList,
    setTotalCommissionList,
    setTotalOrderList,
    setTotalDealerList,

} = dashboardSlice.actions

export const selectTotalOverviewList = (state) => state.dashboard.totalOverviewList
export const selectTotalCommissionList = (state) => state.dashboard.totalCommissionList
export const selectTotalOrder = (state) => state.dashboard.totalOrder
export const selectTotalDealer = (state) => state.dashboard.totalDealer
export const selectTotalAgent = (state) => state.dashboard.totalAgent


export default dashboardSlice.reducer
