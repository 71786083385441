import { createSlice } from "@reduxjs/toolkit"

export const productSlice = createSlice({
    name: "product",
    initialState: {
        productList: [],
        categoryList: [],
        exportList: [],
        productDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },  
    reducers: {
        setProductList: (state, action) => {
            state.productList = action.payload
        },
        setCategoryList: (state, action) => {
            state.categoryList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.productDetail = action.payload
        }
    }
})

export const {
    setProductList,
    setFreshList,
    setCategoryList,
    setExportList,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = productSlice.actions

export const selectProductList = (state) => state.product.productList
export const selectFreshData = (state) => state.product.isFreshData
export const selectCategoryList = (state) => state.product.categoryList
export const selectExportList = (state) => state.product.exportList
export const selectTotalEntry = (state) => state.product.totalEntry
export const selectProductDetail = (state) => state.product.productDetail

export default productSlice.reducer
