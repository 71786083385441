import { createSlice } from "@reduxjs/toolkit"

export const lockerSlice = createSlice({
    name: "locker",
    initialState: {
        lockerList: [],
        dealerList: [],
        isFreshData: false,
        totalEntry: 0,
    },
    reducers: {
        setLockerList: (state, action) => {
            state.lockerList = action.payload
        },
        setDealerList: (state, action) => {
            state.dealerList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
    }
})

export const {
    setLockerList,
    setDealerList,
    setFreshList,
    setTotalEntry,
} = lockerSlice.actions

export const selectLockerList = (state) => state.locker.lockerList
export const selectDealerList = (state) => state.locker.dealerList
export const selectFreshData = (state) => state.locker.isFreshData
export const selectTotalEntry = (state) => state.locker.totalEntry

export default lockerSlice.reducer
