// ** Reducers Imports 
import layout from "./layout"
import navbar from "./navbar"
import auth from './slices/auth.Slice'
import users from './slices/usersSlice'
import dealer from './slices/dealerSlice'
import agent from './slices/agentSlice'
import product from './slices/productSlice'
import order from './slices/orderSlice'
import category from './slices/categorySlice'
import locker from './slices/storeSlice'
import levelAccess from './slices/levelSlice'
import commission from './slices/commissionSlice'
import dashboard from './slices/dashboardSlice'
 
const rootReducer = {
    navbar,
    layout,
    auth,
    dashboard,
    users,
    dealer,
    agent,
    category,
    product,
    order,
    locker,
    levelAccess,
    commission,
 
}

export default rootReducer
