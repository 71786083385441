import { createSlice } from "@reduxjs/toolkit"

export const agentSlice = createSlice({
    name: "agent",
    initialState: {
        agentList: [],
        exportList: [],
        agentDetail: {},
        isFreshData: false,
        isFreshAgent: false,
        totalEntry: 0,
    },
    reducers: {
        setAgentList: (state, action) => {
            state.agentList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setFreshAgent: (state, action) => {
            state.isFreshAgent = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.agentDetail = action.payload
        }
    }
})

export const {
    setAgentList,
    setFreshAgent,
    setFreshList,
    setExportList,
    setSingleData,
    setTotalEntry,
} = agentSlice.actions

export const selectAgentList = (state) => state.agent.agentList
export const selectFreshData = (state) => state.agent.isFreshData
export const selectFreshAgent = (state) => state.agent.isFreshAgent
export const selectExportList = (state) => state.agent.exportList
export const selectTotalEntry = (state) => state.agent.totalEntry
export const selectAgentDetail = (state) => state.agent.agentDetail

export default agentSlice.reducer
