import { createSlice } from "@reduxjs/toolkit"

export const dealerSlice = createSlice({
    name: "dealer",
    initialState: {
        dealerList: [],
        exportList: [],
        dealerDetail: {},
        isFreshData: false,
        totalEntry: 0,
    },    
    reducers: {
        setDealerList: (state, action) => {
            state.dealerList = action.payload
        },
        setFreshList: (state, action) => {
            state.isFreshData = action.payload
        },
        setExportList: (state, action) => {
            state.exportList = action.payload
        },
        setTotalEntry: (state, action) => {
            state.totalEntry = action.payload
        },
        setSingleData: (state, action) => {
            state.dealerDetail = action.payload
        }
    }
})

export const {
    setDealerList,
    setFreshList,
    setExportList,
    setFilterTerm,
    setSingleData,
    setTotalEntry,
    setFilteredList
} = dealerSlice.actions

export const selectDealerList = (state) => state.dealer.dealerList
export const selectFreshData = (state) => state.dealer.isFreshData
export const selectExportList = (state) => state.dealer.exportList
export const selectTotalEntry = (state) => state.dealer.totalEntry
export const selectDealerDetail = (state) => state.dealer.dealerDetail

export default dealerSlice.reducer
